html,
body,
#root {
  height: 100%;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.grabbable {
  cursor: move; /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}

/* (Optional) Apply a "closed-hand" cursor during drag operation. */
.grabbable:active {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}

/* increased the z-index of autocomplete dropdown list that we get from goolge api */
.pac-container {
  z-index: 9999;
}

body > iframe {
  display: none;
}

.displayFlex {
  flex-direction: row !important;
}

.media-nav {
  display: flex;
  align-items: center;
}
.media-btn {
  display: none;
  padding: 0;
  min-width: auto;
}
.user-list {
  display: inline-flex;
  align-items: center;
}

/* admin page styles */
.table-scroll {
  height: calc(100vh - 235px);
  border: 1px solid #707070;
}
.MuiTableContainer-root .MuiTable-stickyHeader .MuiTableHead-root .MuiTableCell-head{
  background:#e1e0e0;
}

.admin-container {
  display: grid;
  grid-template-columns: 280px 1fr;
  gap: 1rem;
}
.admin-container .left-panel {
  position: relative;
}
.admin-container .left-panel .side-menu-btn {
  position: absolute;
  right: -40px;
  background: #fff !important;
  border-radius: 0 8px 8px 0 !important;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px #d3d3d3;
  top: 20px;
  display: none;
}
.admin-container .left-panel .side-menu-btn svg {
  position: relative;
  top: 3px;
  transition: transform 0.2s ease-in-out;
}
.admin-container .left-panel .left-panel--nav {
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px #d3d3d3;
  background-color: #ffffff;
  border-radius: 16px;
  height: 100%;
}
.admin-container .left-panel .left-panel--nav h2 {
  font-size: 1em;
}
.admin-container .right-panel h2 {
  font-size: 18px;
  font-weight: bold;
  margin: 0;
}

/* Assessments styles */
.left-assessments {
  position: relative;
}
.left-assessments .assessments-btn {
  position: absolute;
  right: -40px;
  background: #fff !important;
  border-radius: 0 8px 8px 0 !important;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px #d3d3d3;
  top: 20px;
  display: none;
}
.left-assessments .assessments-btn svg {
  position: relative;
  top: 3px;
  transition: transform 0.2s ease-in-out;
}
.left-assessments .left-assessments--nav {
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px #d3d3d3;
  background-color: #ffffff;
  border-radius: 16px;
  height: 100%;
}
.left-assessments .left-assessments--nav h2 {
  font-size: 1em;
}

/* table */
.MuiTable-root {
}
.MuiTable-root .MuiTableHead-root {
}
.MuiTable-root .MuiTableHead-root .MuiTableRow-root .MuiTableCell-head {
  font-size: 1em;
  font-weight: 600;
  white-space: nowrap;
  min-width: 120px;
}
.MuiTableBody-root .MuiTableRow-root .MuiTableCell-root,
.MuiTableBody-root .MuiTableRow-root .MuiTableCell-root p {
  font-size: 1em;
}
.facility-card > div:first-child > div {
  grid-template-columns: 1fr;
}
.medi-list{
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 1em;
  margin-top: 1.5em;
}
.medi-list-d{
  position: relative;
  padding-left: 10px;
}
.medi-list-d:before{
  position: absolute;
  content: ':';
  left: 0;
}
.scsf-gap{
  display: flex;
  flex-direction: column;
  gap: 1em;
}
.street-address input{
  background-repeat: no-repeat;
  background-position: 98% center;
}
.MuiDialogTitle-root{
  min-height: 60px;
}
.MuiDialogTitle-root > div{
  font-size: 26px;
  font-weight: 600;
}
.MuiDialogTitle-root > button{
  padding: 6px;
}
.MuiDialogTitle-root > button,
.MuiDialogTitle-root > button svg{
  font-size: 28px;
}
.MuiDialogContent-root{
  margin-bottom: 3em;
}
.range-setting{
  min-height: 250px;
}
.home-filter{
  position: relative;
  gap:1em;
}
.home-filter .search-bar{
  max-width: 220px;
}
.home-list{
  margin-top: -34px;
}
.secondary-sort{
  width: clamp(130px, 60%, 250px);
  border: 1px solid #707070;
  overflow: hidden;
  border-radius: 8px;
  background-color: #fff;
  height: 35px;
  padding: initial;
  position: relative;
  margin-bottom: 1em;
}
.dflex{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.progressBarContainer{
  position: relative;
  width: clamp(650px, 90%, 800px);
  background-color: #fff;
  border-radius: 12px;
  border: 1px solid #999;
  left: -250px;
}

.CSAInput .MuiRadioGroup-root .MuiFormControlLabel-root:nth-child(1){
  width:185px;
} 
.CSAInput .MuiRadioGroup-root .MuiFormControlLabel-root:nth-child(2){
  width:126px;
} 
.CSAInput .MuiRadioGroup-root .MuiFormControlLabel-root:nth-child(3){
  width:50px;
} 
.time-box .MuiInputBase-input{
  max-width: 75px;
}
.remove-hover{
  padding: 0;
  width: auto;
  }
  .remove-hover svg{
  margin: 0;
  }  
.remove-hover:hover{
  background-color: transparent !important;
}
/* media styles */
@media (max-width: 1480px){
  .home-filter .search-bar{
    max-width: 130px;
  }
}
@media (max-width: 1200px) {
  .media-btn {
    display: block;
  }
  .media-btn svg {
    display: none;
  }
  .media-btn svg.show-icon {
    display: block;
  }
  .media-nav {
    transform: translateX(200%);
    position: fixed;
    background-color: #1b464e;
    transition: transform 0.3s ease-in-out;
    opacity: 0;
    max-width: 250px;
    width: 100%;
    padding: 1rem;
  }
  .media-nav.show-nav {
    flex-direction: column;
    transform: none;
    top: 71px;
    right: 0;
    bottom: 0;
    z-index: 99;
    opacity: 1;
    align-items: flex-start;
  }
  .media-nav.show-nav .nav-list {
    flex-direction: column;
    margin: 1.5em 0;
  }
  .media-nav.show-nav .nav-list > * {
    padding: 0.5em 0;
  }
  /* admin media styles */
  .admin-container {
    display: grid;
    grid-template-columns: 100%;
    gap: 0;
  }
  .admin-container .left-panel {
    position: fixed;
    top: 70px;
    left: -290px;
    bottom: 0;
    width: 290px;
    z-index: 99;
    transition: transform 0.3s ease-in-out;
  }
  .admin-container .left-panel .side-menu-btn {
    display: block;
  }
  .admin-container .left-panel.show-left-nav .side-menu-btn svg {
    transform: rotate(180deg);
  }
  .admin-container .left-panel.show-left-nav {
    left: 0;
  }
  .admin-container .left-panel.show-left-nav .left-panel--nav {
    border-radius: 0;
    overflow-x: hidden;
  }

  /* Assessments styles */
  .left-assessments {
    position: fixed;
    top: 70px;
    left: -290px;
    bottom: 0;
    width: 290px;
    z-index: 99;
    transition: transform 0.3s ease-in-out;
  }
  .left-assessments .assessments-btn {
    display: block;
  }
  .left-assessments.show-assessments .assessments-btn svg {
    transform: rotate(180deg);
  }
  .left-assessments.show-assessments {
    left: 0;
  }
  .left-assessments.show-assessments .left-assessments--nav {
    border-radius: 0;
    overflow-x: hidden;
  }
  .home-filter{
    display: grid;
    grid-template-columns: 3fr 1fr 1fr;
    top: inherit;
    margin-bottom: 1em;
  }
  .home-filter .search-bar{
    max-width: inherit;
  }
  .home-list{
    margin-top: 0px;
  }
}
@media (max-width:900px){
  .home-filter{
    grid-template-columns: 1fr 1fr;
    top: inherit;
    margin-bottom: 1em;
  }
  .home-filter > div:first-child{
    grid-column: span 2; 
  }
  .input-field{
    &:nth-child(15){
      display: none;
    }
  }
}
@media (max-width: 600px) {
  .range-setting{
    min-height: auto;
  }
  .home-filter{
    grid-template-columns: 1fr;
  }
  .home-filter > div:first-child{
    grid-column: inherit; 
  }
  .secondary-sort{
    width: 100%;
  }
}

@keyframes blinking {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.3;
  }
  100% {
    opacity: 1;
  }
}
