/* Styles go here */

.page-header, .page-footer {
    height: fit-content;
    display: none;
    background: #fff;
}

.page-header-space, .page-footer-space {
    height: fit-content;
    visibility: hidden;
    background: #fff;
}

.page-header-space {
    padding-bottom: 60px;
}

.page-footer-space {
    padding-top: 60px;
}

.page-footer {
    position: fixed;
    bottom: 0;
    width: 100%;
}

.page-header {
    position: fixed;
    top: 0;
    width: 100%;
}

.page-table {
    display: none;
}

.page-content {
    font-size: 36px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #000;
    padding: 0 144px;
    background: #fff;
}
